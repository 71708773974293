@import '@popsure/dirty-swan/dist/colors';
@import '@popsure/dirty-swan/dist/grid';

.buttonContainer {
  @include p-size-mobile {
    flex-direction: column;
  }
}

.link {
  background-color: $ds-white;
}
